class Ajax {
    constructor(ajaxUrl = '/site/ajax') {
        this.url = ajaxUrl;
        this.obj = {};
    }
    send(success = null, failure = null) {
        $.post(this.url, this.obj, (answer) => {
            answer.result && typeof success === 'function' ? success(answer) :
                !answer.result && typeof failure === 'function' ? failure(answer) : null;
        }, 'JSON');
    }
}

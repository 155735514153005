/**
 @module Catalog
 @author Zbarazskiy
 @date 14.01.2021
 @version 0.3
 **/
let Catalog = {
    onload: document.addEventListener('DOMContentLoaded', function () {
        Catalog.init();
    }),
    init: function () {
        this.ajax.url = document.querySelector('meta[name="ajaxUrl"]').content;
        this.bind();
    },
    bind: function () {
         $(document).on('change', '.catalog-accordion-content .catalog-accordion-content-blockCheck', function (e) {
            let items = {
                'type': [],
                'catalog': []
            };
            const catalog = $('.catalog-accordion-content-blockCheck');
            catalog.each((index, item) => {
                if ($(item).data('type') == 'type') {
                    if ($(item).find('.zbara').is(':checked')) {
                        items.type.push($(item).data('id'))
                    }
                } else if ($(item).data('type') == 'catalog') {
                    if ($(item).find('.zbara').is(':checked')) {
                        items.catalog.push($(item).data('id'))
                    }
                }
            })


            // if ('URLSearchParams' in window) {
            //     var searchParams = new URLSearchParams(window.location.search);
            //     let url = [];
            //     if(items.type.length > 0){
            //         url.push('type:' + items.type.join(','));
            //     } else if(items.catalog.length > 0){
            //         url.push('catalog:' + items.catalog.join(','));
            //     }
            //     searchParams.set('filter', url.join('='));
            //     var newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
            //     history.pushState(null, '', newRelativePathQuery);
            // }


            Catalog.ajax.loadCatalog(items)
        }).on('click', 'a#save-certificate', function (e) {
            const image = $('.swiper-slide-active').find('img.save');
            const link = document.createElement('a');
            link.setAttribute('href', image.prop("currentSrc"));
            link.setAttribute('download', image.data('name'));
            link.click();

        }).on('click', 'a#print-certificate', function (e) {
            const image = $('.swiper-slide-active').find('img.save');
            Catalog.printImage(image.prop("currentSrc"))
        }).on('change', '#limit__product', function () {
            Catalog.ajax.setLimit($(this).val());
        });
    },
    printImage: function (src) {
        printJS({
            printable: [src],
            type: 'image',
            documentTitle: '',
            imageStyle: 'width:100%;height:100%;margin-bottom:20px;'
        })
    },
    ajax: {
        url: undefined,
        obj: {},
        send: function (success, failure) {
            $.post(this.url, this.obj, (answer) => {
                this.obj = {};
                answer.result && typeof success === 'function' ? success(answer) :
                    !answer.result && typeof failure === 'function' ? failure(answer) : null;
                Catalog.ajax.obj = {};
            }, 'JSON');
        },
        setLimit: function (count){
            this.obj.method = 'setCatalogLimit';
            this.obj.count = parseInt(count);
            this.send(function (data) {
                Catalog.ajax.loadCatalog({
                    'type': [],
                    'catalog': []
                });
            }, (answer) => {
                console.log(answer);
            });
        },
        loadCatalog: function (data) {
            this.obj.method = 'getCatalog';
            this.obj.catalogType = data.type.join(',');
            this.obj.catalogItems = data.catalog.join(',');
            this.send(function (data) {
                $('.catalog-product-cart').html(data.html).show();
                $('.catalog-product-page').html(data.pagination).show();
            }, (answer) => {
                console.log(answer);
            });
        },
    }
}
/**
 @module contacts
 @author Zbarazskiy
 @date 28.29.2020
 @version 0.3
 **/
let Contacts = {
    onload: document.addEventListener('DOMContentLoaded', function () {
        Contacts.init();
    }),
    init: function () {
        this.ajax.url = document.querySelector('meta[name="ajaxUrl"]').content;
        this.bind();
    },
    bind: function () {
        $('#contacts').on('change', function () {
            return Contacts.ajax.checkCity($(this).val());
        });
    },
    reload: function (){
        let contacts = document.querySelector(".contacts")
        if (contacts) {
            var acc = document.getElementsByClassName("contacts-info-content-accordion");
            var i;

            for (i = 0; i < acc.length; i++) {
                acc[i].addEventListener("click", function() {
                    this.classList.toggle("active");
                    var panel = this.nextElementSibling;
                    if (panel.style.display === "block") {
                        panel.style.display = "none";

                    } else {
                        panel.style.display = "block";
                    }
                });
            }
        }


        let locations = [];
        let titles = [];
        const offices = document.querySelectorAll('.footer-contacts__offices');
        offices.forEach(office => {
            const lat = +office.dataset.lat;
            const lng = +office.dataset.lng;
            const zoom = +office.dataset.zoom ? +office.dataset.zoom : 15;
            const title = office.dataset.title;
            locations.push({lat, lng});
            titles.push(title);
            office.addEventListener('click', function () {
                map.setCenter({lat, lng});
                map.setZoom(zoom);
            })
        });
        GMap.init(locations, titles);
    },
    ajax: {
        url: undefined,
        obj: {},
        send: function (success, failure) {
            $.post(this.url, this.obj, (answer) => {
                this.obj = {};
                answer.result && typeof success === 'function' ? success(answer) :
                    !answer.result && typeof failure === 'function' ? failure(answer) : null;
                Contacts.ajax.obj = {};
            }, 'JSON');
        },
        checkCity: function ($cityId) {
            this.obj.method = 'getCity';
            this.obj.cityId = parseFloat($cityId);
            this.send(function (data) {
                $('#ajax-update').html(data.html).show();
                Contacts.reload();
            }, (answer) => {
                console.log(answer);
            });
        },
    }
}
let body = document.querySelector("body")
let main = document.querySelector(".main")
    if (main) {
        const headerMainAnima = document.querySelector(".header")
        headerMainAnima.classList.add("header-main-anima")
        window.onload = function () {
            const animaLogo = document.querySelector(".main-block-content__logo")
            const animaMenu = document.querySelector(".main-block-content-menu")
            const animaHeader = document.querySelector(".header")
            document.body.classList.add('tesHiden')
            window.setTimeout(function () {
                animaLogo.classList.add('logo-anima');
                animaMenu.classList.add('menu-anima')
            }, 0);
        }

        var swiper = new Swiper('.swiper-container', {
            speed: 400,
        });
        const mainSlide = document.querySelectorAll(".swiper-slide");
        const mainTextHover = document.querySelectorAll(".main-menu-item");

        const bglastslide = document.querySelector(".main__bg-last")
        const mainBg = document.querySelector(".main__bg")
        console.log(window.innerWidth)
        function animaMainslider() {
            if (window.innerWidth > 1024) {
                mainSlide.forEach((item, index) => {
                    if (mainTextHover[index]) {
                        mainTextHover[index].addEventListener("mouseover",function () {
                            swiper.slideTo(index+1)
                            bglastslide.style.opacity = "0"
                        })
                        mainTextHover[index].addEventListener("mouseout",function () {
                            bglastslide.style.opacity = "1"
                        })
                    }
                })
            }
        }
        animaMainslider()


        let buttonTop = document.querySelector(".anchor");
        window.onscroll = function() {scrollFunction()};
        function scrollFunction() {
            if (document.body.scrollTop > 1000 || document.documentElement.scrollTop > 1000) {
                buttonTop.style.opacity = "1";
            } else {
                buttonTop.style.opacity = "0";
            }
        }
    }



let mainNews = document.querySelector(".main-news")
if (mainNews) {
    // Слайдер с новостоями
    let swiperNews = new Swiper('.swiper-container-news', {
        wrapperClass:"swiper-wrapper-news",
        slideClass:"slide-news",
        slidesPerView:3,
        spaceBetween:50,
        allowTouchMove:false,
        breakpoints: {
            320: {
                allowTouchMove:true,
                slidesPerView:1,
                spaceBetween:10,
            },
            426: {
                slidesPerView:1,
                allowTouchMove:true,
                spaceBetween:25,
            },
            490: {
                allowTouchMove:true,
                slidesPerView:2,
                spaceBetween:25,
            },
            768: {
                slidesPerView:2,
                spaceBetween:25,
                allowTouchMove:true,
            },
            1024:{
                slidesPerView:3,
                allowTouchMove:true,
            }
        },
        navigation: {
            nextEl: '.swiper-button-next-news',
            prevEl: '.swiper-button-prev-news',
        },
    });

    // start анимация для блоков новостей, появление картинки

    const newsImg = new ScrollMagic.Controller({refreshInterval: 1});

    let newsImgAnima = new gsap.timeline();
    newsImgAnima
        .to(".news__img",{
            top:0
        })
    const newsImgAnimaTrigger = new ScrollMagic.Scene({
        triggerElement: ".main-news",
        triggerHook: "0.6",
    })
        .setTween(newsImgAnima)
        .addTo(newsImg)
    // .addIndicators({name:"newsImg"})

//end
}


let news = document.querySelector(".news");
if (news) {
    body.style.background  = "#F7F7F9"
    let breadcrumb = document.querySelector(".breadcrumb")
    breadcrumb.style.background  = "#F7F7F9"

    let btnViewActive = document.querySelector(".news-view__button-active");
    let btnView = document.querySelector(".news-view__button");
    let newsContent = document.querySelector(".news-content");
    let newsItem = document.querySelectorAll(".news-item");
    let newsItemImg = document.querySelectorAll(".news-item__img");
    let newsImg = document.querySelectorAll(".news-item-img")
    let newsBtn = document.querySelectorAll(".news-item__btn");
    let NewsItemBtn = document.querySelectorAll(".news-item__btn")
    let NewsItemLink = document.querySelectorAll(".news-item__link")

    let newsViewRow = document.querySelector(".news-view__button");
    let newsViewCol = document.querySelector(".news-view__button-active");


    btnViewActive.addEventListener("click",function (){
        newsContent.classList.add("news-content__view")
        newsItem.forEach(item=>{
            item.classList.add("news-item__view")
        })

        newsImg.forEach(item=>{
            item.classList.add("news-item-img__active")
        })

        newsItemImg.forEach(item=>{
            item.classList.add("news-item__img__active")
        })
        newsBtn.forEach(item=>{
            item.classList.add("news-btn__active")
        })
        newsViewRow.classList.add("button-row-active")
        newsViewCol.classList.add("button-col-active")
        NewsItemBtn.forEach(item=>{
            item.classList.add("news-item__btn__row")
        })
        NewsItemLink.forEach(item=>{
            item.classList.add("news-item__link__row")
        })
    })


    btnView.addEventListener("click",function (){
        newsContent.classList.remove("news-content__view")

        newsItem.forEach(item=>{
            item.classList.remove("news-item__view")
        })

        newsItemImg.forEach(item=>{
            item.classList.remove("news-item__img__active")
        })

        newsImg.forEach(item=>{
            item.classList.remove("news-item-img__active")
        })

        newsBtn.forEach(item=>{
            item.classList.remove("news-btn__active")
        })
        newsViewRow.classList.remove("button-row-active")
        newsViewCol.classList.remove("button-col-active")
        NewsItemBtn.forEach(item=>{
            item.classList.remove("news-item__btn__row")
        })
        NewsItemLink.forEach(item=>{
            item.classList.remove("news-item__link__row")
        })

    })
    var truncate = document.querySelectorAll(".news-item__subtitle");
    var newsTitle = document.querySelectorAll(".news-item__title")
    truncate.forEach(function (item){
        $clamp(item, {
            clamp: 4, // Число строк
            useNativeClamp: false // НЕ используем -webkit-line-clamp
        });
    })
    newsTitle.forEach(function (item){
        $clamp(item, {
            clamp: 2, // Число строк
            useNativeClamp: false // НЕ используем -webkit-line-clamp
        });
    })
}











// Гамбургер
let HamburgerIcon = document.querySelector(".header-hamburger__icon");
let HamburgerMenu = document.querySelector(".hamburger");
let HamburgerMenuClose = document.querySelector(".close");
let HamburgerDrop = document.querySelector(".header-menu-drop")
let HamburgerDropMenu = document.querySelector(".drop-menu-mob")



HamburgerIcon.addEventListener("click",function (){
    HamburgerMenu.style.display ="flex"
    document.body.style.overflow = "hidden"
})

HamburgerMenuClose.addEventListener("click",function (){
    HamburgerMenu.style.display ="none"
    document.body.style.overflow = "visible"
})

HamburgerDrop.addEventListener("click", function (){
    HamburgerDropMenu.classList.toggle("active")
})

// end


let itemNews = document.querySelector(".item-news")
    if (itemNews) {
        body.style.background  = "#F7F7F9"
        var swiperContentNews = new Swiper('.content-news-swiper-container', {
            wrapperClass:"content-news-swiper-wrapper",
            slideClass:"content-news-swiper-slide",
            slidesPerView:1,
            spaceBetween:10,
            navigation: {
                nextEl: '.content-news-btnNext',
                prevEl: '.content-news-btnPrev',
            },
            pagination: {
                 el: ".content-news-slider-pagination",
                 clickable: true,
                 renderBullet: function (index, className) {
                   return '<span class="' + className + '">' + (index + 1) + "</span>";
                 },
            },
            breakpoints: {
                560: {
                    slidesPerView: 2,
                    spaceBetween: 20
                },
                991: {
                    slidesPerView: 3,
                    spaceBetween: 20
                },
                1200: {
                    slidesPerView: 4,
                    spaceBetween: 30
                }
            },
        });

        var swiperContentAllNews = new Swiper('.slider-allnews-container', {
            wrapperClass:"slider-allnews-wrapper",
            slideClass:"slider-allnews-slide",
            slidesPerView:3,
            spaceBetween:50,
            navigation: {
                nextEl: '.slider-allnews__next',
                prevEl: '.slider-allnews__prev',
            },
            breakpoints: {
                // when window width is >= 320px
                320: {
                    slidesPerView: 1,
                    spaceBetween: 20
                },
                // when window width is >= 480px
                525: {
                    slidesPerView: 2,
                    spaceBetween: 30
                },
                // when window width is >= 640px
                768: {
                    slidesPerView: 4,
                    spaceBetween: 30
                }
            },
        });
        var truncate = document.querySelectorAll(".slider-allnews-slide-content__subtitle");
        var newsTitle = document.querySelectorAll(".slider-allnews-slide-content__title")
        truncate.forEach(function (item){
            $clamp(item, {
                clamp: 4, // Число строк
                useNativeClamp: false // НЕ используем -webkit-line-clamp
            });
        })
        newsTitle.forEach(function (item){
            $clamp(item, {
                clamp: 2, // Число строк
                useNativeClamp: false // НЕ используем -webkit-line-clamp
            });
        })
    }






let catalog = document.querySelector(".catalog")
if (catalog) {
    body.style.background  = "#F7F7F9"
    //accardion
    var acc = document.querySelectorAll(".catalog-accordion");
    var i;

    for (i = 0; i < acc.length; i++) {
        acc[i].addEventListener("click", function() {
            this.classList.toggle("active");
            var panel = this.nextElementSibling;
            if (panel.style.display === "block") {
                panel.style.display = "none";
            } else {
                panel.style.display = "block";
            }
            // console.log(this.nextElementSibling)
        });
    }

    //переключение колонок

    let cartItem = document.querySelectorAll(".cart-item");
    let cartBtn = document.querySelectorAll(".cart-item__btn")
    let cartInfo = document.querySelectorAll(".cart-item-info")


    let catalogCol = document.querySelector(".catalog-view-col")
    let catalogRow = document.querySelector(".catalog-view-row")


    catalogRow.addEventListener("click",function () {
        cartItem.forEach(item=>{
            item.classList.add("cart-row")
        })
        cartBtn.forEach(item=>{
            item.classList.add("cart-row__btn")
        })
        cartInfo.forEach(item=>{
            item.classList.add("cart-item-info__row")
        })

        catalogRow.classList.add("button-row-active")
        catalogCol.classList.add("button-col-active")

    })

    catalogCol.addEventListener("click",function () {
        cartItem.forEach(item=>{
            item.classList.remove("cart-row")
        })
        cartBtn.forEach(item=>{
            item.classList.remove("cart-row__btn")
        })
        cartInfo.forEach(item=>{
            item.classList.remove("cart-item-info__row")
        })
        catalogRow.classList.remove("button-row-active")
        catalogCol.classList.remove("button-col-active")
    })
    var truncate2 = document.querySelectorAll(".cart-item__title");
    var newsTitle2 = document.querySelectorAll(".cart-item__subtitle")
    truncate2.forEach(function (item){
        $clamp(item, {
            clamp: 2, // Число строк
            useNativeClamp: false // НЕ используем -webkit-line-clamp
        });
    })
    newsTitle2.forEach(function (item){
        $clamp(item, {
            clamp: 4, // Число строк
            useNativeClamp: false // НЕ используем -webkit-line-clamp
        });
        console.log("sdqd")
    })

}


let partners = document.querySelector(".partners")

    if (partners) {
        // Слайдер с партнерами
        var swiperPartners = new Swiper('.swiper-container-partners', {
            wrapperClass:"swiper-wrapper-partners",
            slideClass:"swiper-slide-partners",
            slidesPerView:4,
            loop: {
                loop:true,
                loopedSlides:true
            },
            breakpoints: {
                320: {
                    slidesPerView:2,
                    slidesOffsetBefore:0,
                    spaceBetween:20,
                },
                426: {
                    slidesPerView:3,
                    spaceBetween:25,
                },
                490: {
                    slidesPerView:3,
                    spaceBetween:25,
                },
                768: {
                    slidesPerView:3,
                    spaceBetween:25,
                },
                1024:{
                    slidesPerView:4,
                    spaceBetween:25,
                }

            },
            navigation: {
                nextEl: '.swiper-button-partners-next',
                prevEl: '.swiper-button-partners-prev',
            },
        });
        // start анимация паралакса

        const partnersParallax = new ScrollMagic.Controller({refreshInterval: 1});

        let partnersParallaxAnima = new gsap.timeline();
        partnersParallaxAnima
            .fromTo(".partners__bg",{
                y:"-100%",
            },{
                y:-150
            })

        .to(".partners__bg",{
            y:0
            })


        const partnersParallaxTrigger = new ScrollMagic.Scene({
            triggerElement: ".partners",
            triggerHook: "0.95",
            duration: "100%",
            offset:-50
        })
            .setTween(partnersParallaxAnima)
            .addTo(partnersParallax)
            // .addIndicators({name:"partnersParallax"})

//end
    }









const productPage = document.querySelector(".product-main")
if (productPage) {
    body.style.background = "#F7F7F9"
    var acc = document.getElementsByClassName("info-accordion");
    var i;

    for (i = 0; i < acc.length; i++) {
        if (window.innerWidth > 1024) {
            acc[i].classList.toggle("active");
            acc[i].nextElementSibling.style.display = "block"
        }
        acc[i].addEventListener("click", function() {
            this.classList.toggle("active");
            var panel = this.nextElementSibling;
            if (panel.style.display === "block") {
                panel.style.display = "none";
            } else {
                panel.style.display = "block";
            }
        });
    }
    var swiperProductAll = new Swiper('.product-all-container', {
        wrapperClass:"product-all-wrapper",
        slideClass:"product-all-slide",
        slidesPerView: 4,
        spaceBetween:40,
        breakpoints: {
            // when window width is >= 320px
            320: {
                allowTouchMove:false,
                slidesPerView:"auto",
                spaceBetween:0,
            },
            // when window width is >= 480px
            526: {
                slidesPerView: 2,
                spaceBetween: 30
            },
            // when window width is >= 640px
            768: {
                slidesPerView: 4,
                spaceBetween: 30
            }
        },
        navigation: {
            nextEl: '.product-all-button-next',
            prevEl: '.product-all-button-prev',
        },
    });
    $(document).ready(function () {
        var galleryThumbs = new Swiper(".gallery-thumbs", {
            direction: "vertical",
            spaceBetween: 8,
            slidesPerView:"auto",
            preventInteractionOnTransition: true,
            // slidesPerView: 2,
            // freeMode: false,
            // watchSlidesVisibility: true,
            // watchSlidesProgress: true,
            // breakpoints: {
            // }
        });

        var galleryTop = new Swiper(".gallery-top", {
            spaceBetween: 10,
            slidesPerView: 1,
            thumbs: {
                swiper: galleryThumbs
            }
        });

        $('.swiper-zoom-container img').each(function () {
            $(this)
                .parent()
                .zoom({
                    magnify: 2,
                });
        });
    });
}
const modalPrice = document.querySelector(".modal-form-price")
if(modalPrice) {
    const modalPriceOpen = document.querySelector(".open-price")
    const modalPriceClosed = document.querySelector(".modal-form-price-closed")
    modalPriceOpen.addEventListener("click",function (e){
        e.preventDefault()
        modalPrice.classList.add("form-price-show")
        body.style.overflow = "hidden"
    })
    modalPriceClosed.addEventListener("click",function (){
        modalPrice.classList.remove("form-price-show")
        body.style.overflow = "visible"
    })
}
const certificatesModal = document.querySelector(".certificates-modal")
    if (certificatesModal) {
        var swiperCertificates = new Swiper('.certificates-swiper-container', {
            wrapperClass:"certificates-swiper-wrapper",
            slideClass:"certificates-swiper-slide",
            slidesPerView:1,
            // spaceBetween:20,
            slideToClickedSlide:true,
            // centeredSlides: false,
            navigation: {
                nextEl: '.certificates-swiper-button-next',
                prevEl: '.certificates-swiper-button-prev',
            },
            pagination: {
                el: '.certificates-swiper-pagination',
                type: 'fraction',
            },
            breakpoints: {
                1024: {
                    slidesPerView: 2.7,
                    centeredSlides: true,
                    // spaceBetween:40,
                }
            },
        });
        const certificatesModal = document.querySelector(".certificates-modal")
        const certificatesOpen = document.querySelector(".certificates")
        const certificatesModalClosed = document.querySelector(".certificates-modal-navigation-closed")
        const modalForm = document.querySelector(".form-modal")
        const modalFormOpen = document.querySelector(".window-modal-form")
        const modalFormClosed = document.querySelector(".form-modal-closed")
        const modalPrice = document.querySelector(".modal-form-price")


        certificatesOpen.addEventListener("click",function (){
            certificatesModal.classList.add("certificates-modal-open")
            body.style.overflow = "hidden"
        })
        certificatesModalClosed.addEventListener("click",function (e){
            e.preventDefault()
            certificatesModal.classList.remove("certificates-modal-open")
            body.style.overflow = "visible"
        })

        modalFormOpen.addEventListener("click", function (){
            event.preventDefault();
            modalForm.classList.add("form-modal-show")
            body.style.overflow = "hidden"
        })
        modalFormClosed.addEventListener("click",function () {
            modalForm.classList.remove("form-modal-show")
            body.style.overflow = "visible"
        })

    }


let contacts = document.querySelector(".contacts")
    if (contacts) {
        var acc = document.getElementsByClassName("contacts-info-content-accordion");
        var i;

        for (i = 0; i < acc.length; i++) {
            acc[i].addEventListener("click", function() {
                this.classList.toggle("active");
                var panel = this.nextElementSibling;
                if (panel.style.display === "block") {
                    panel.style.display = "none";

                } else {
                    panel.style.display = "block";
                }
            });
        }
    }

let directing = document.querySelector(".directing")

if (directing) {
   let footer = document.querySelector(".footer")
    let partners = document.querySelector(".partners")
    footer.style.marginTop = "0"
    partners.style.marginBottom = "0"

    let buttonTop = document.querySelector(".anchor");
    window.onscroll = function() {scrollFunction()};
    function scrollFunction() {
        if (document.body.scrollTop > 1000 || document.documentElement.scrollTop > 1000) {
            buttonTop.style.opacity = "1";
        } else {
            buttonTop.style.opacity = "0";
        }
    }

}


const CompanyAbout = document.querySelector(".company-about-main");
    if(CompanyAbout) {
        let buttonTop = document.querySelector(".anchor");
        window.onscroll = function() {scrollFunction()};
        function scrollFunction() {
            if (document.body.scrollTop > 1000 || document.documentElement.scrollTop > 1000) {
                buttonTop.style.opacity = "1";
            } else {
                buttonTop.style.opacity = "0";
            }
        }

        const priorutyLine = new ScrollMagic.Controller({refreshInterval: 1});

        let priorutyLineAnima = new gsap.timeline();
        priorutyLineAnima
            .to(".stories-right-priority__line",{
                width:"100%",
                duration: 1
            })
        const priorutyLineAnimaTrigger = new ScrollMagic.Scene({
            triggerElement: ".stories-right",
            triggerHook: "0.5",
        })
            .setTween(priorutyLineAnima)
            .addTo(priorutyLine)


        const philosophyText = new ScrollMagic.Controller({refreshInterval: 1});

        let philosophyAnima = new gsap.timeline();
        philosophyAnima
            .to(".philosophyAnima",{
                opacity:1,
                y:0
            })
        const philosophyAnimaTrigger = new ScrollMagic.Scene({
            triggerElement: ".philosophy",
            triggerHook: "0.5",
        })
            .setTween(philosophyAnima)
            .addTo(philosophyText)

        const philosophyBlockLine = new ScrollMagic.Controller({refreshInterval: 1});

        let philosophyBlockLineAnima = new gsap.timeline();
        philosophyBlockLineAnima
            .to(".philosophy-block-line",{
                width: "63px",
                duration:0.6
            })
        const philosophyBlockAnimaTrigger = new ScrollMagic.Scene({
            triggerElement: ".philosophy-block",
            triggerHook: "0.5",
        })
            .setTween(philosophyBlockLineAnima)
            .addTo(philosophyBlockLine)

        const consultationText = new ScrollMagic.Controller({refreshInterval: 1});
        let consultationTextAnima = new gsap.timeline();
        consultationTextAnima
            .to(".consultation-text-anima",{
                opacity: 1,
                y:0,
                duration:0.6
            })
        const consultationTextAnimaTrigger = new ScrollMagic.Scene({
            triggerElement: ".consultation",
            triggerHook: "0.5",
        })
            .setTween(consultationTextAnima)
            .addTo(consultationText)

    }

let map = null;
const GMap = {
    onload: document.addEventListener('DOMContentLoaded', function () {
        let locations = [];
        let titles = [];
        const offices = document.querySelectorAll('.footer-contacts__offices');
        offices.forEach(office => {
            const lat = +office.dataset.lat;
            const lng = +office.dataset.lng;
            const zoom = +office.dataset.zoom ? +office.dataset.zoom : 15;
            const title = office.dataset.title;
            locations.push({lat, lng});
            titles.push(title);
            office.addEventListener('click', function () {
                map.setCenter({lat, lng});
                map.setZoom(zoom);
            })
        });
        if (document.getElementById('map')) {
            GMap.init(locations, titles);
        }
    }),
    init: function (locations, titles) {
        map = new google.maps.Map(document.getElementById('map'), {
            center: { lat: 49.44293991743947, lng: 32.065229164426604 },
            zoom: 0,
            fullscreenControl: false,
            styles: [
                {
                    "featureType": "administrative",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#444444"
                        }
                    ]
                },
                {
                    "featureType": "landscape",
                    "elementType": "all",
                    "stylers": [
                        {
                            "color": "#f2f2f2"
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "all",
                    "stylers": [
                        {
                            "saturation": -100
                        },
                        {
                            "lightness": 45
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "simplified"
                        }
                    ]
                },
                {
                    "featureType": "road.arterial",
                    "elementType": "labels.icon",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "transit",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "all",
                    "stylers": [
                        {
                            "color": "#004783"
                        },
                        {
                            "visibility": "on"
                        }
                    ]
                }
            ]
        });

        GMap.infoWin = new google.maps.InfoWindow();

        let bounds = new google.maps.LatLngBounds();

        let markers = locations.map(function (location, i) {
            let marker = new google.maps.Marker({
                position: location,
                title: titles[i],
                icon: "/src/img/map/marker.png"
            });

            bounds.extend(location);

            map.fitBounds(bounds);
            let listener = google.maps.event.addListener(map, "idle", function () {
                if (map.getZoom() > 16) map.setZoom(16);
                google.maps.event.removeListener(listener);
            });

            google.maps.event.addListener(marker, 'click', function (evt) {
                GMap.infoWin.setContent(titles[i]);
                GMap.infoWin.open(map, marker);
            });

            return marker;
        });

        const markerCluster = new MarkerClusterer(map, markers, {
            imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
        });
    }
};

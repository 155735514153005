var Form = {
    $form: undefined, ID: undefined,
    onload: document.addEventListener('DOMContentLoaded', function() { Form.init(); }),
    init: function() {
        this.ajax.url = document.querySelector('meta[name="ajaxUrl"]').content;
        this.bind();
    },
    bind: function() {
        $(document).on('click', 'button.form-submit', function(e) {
            e.preventDefault();
            let $submitBtn = $(this);
            // let $submitBtn = $(this).prop('disabled', true);
            Form.$form = $submitBtn.closest('form[data-type]');

            Form.ajax.formSubmit($submitBtn);

        }).on('input', 'form[data-type] input', function() {
            $(this).parent().removeClass('has-error');
        });
    },

    ajax: {
        url: undefined, obj: {}, send: function(success, failure) {
            $.post(this.url, this.obj, (answer) => {
                this.obj = {};
                answer.result && typeof success === 'function' ? success(answer) :
                    !answer.result && typeof failure === 'function' ? failure(answer) : null;
                Form.ajax.obj = {};
            }, 'JSON');
        },

        formSubmit: function($submitBtn) {

            let formData = Form.$form.serializeArray()

            this.obj.method = 'formSubmit';
            this.obj.formTypeID = parseInt(Form.$form.data('type'));
            this.obj.data = formData;
            this.send(function() {
                $($submitBtn.data('href')).addClass('active');
                Form.$form.get(0).reset();
                $submitBtn.prop('disabled', false);

                setTimeout(function(){
                    $('#popups--thank').removeClass('active');
                    $('#popups__form').removeClass('active');


                    if(Form.$form.data('type') == 1) {
                        $('.form-modal-content-done').show();
                        $('#form-footer').hide();
                    } else if(Form.$form.data('type') == 5){
                        $('#form-open-directing').show();
                        $('#form-hide-directing').hide();
                    }
                }, 100);

            }, (answer) => {
                $.each(answer.error, (k, v) => {
                    Form.$form.find(`[name="${k}"]`).addClass('input-error');
                    Form.$form.find(`[name="${k}"]`).css({"border-color": "rgb(255 0 0)"});
                });
            });
        },
    }
};